<template>
  <div class="top-wrapper">
    <h6>Products Management</h6>
    <router-link class="custom-btn2" to="/add-products">
      Create Product
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ProductsTop",
  methods: {},
};
</script>

<style></style>
