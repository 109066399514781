<template>
  <table class="table table-responsive-md table-sm">
    <thead class="dark">
      <tr>
        <th scope="col" class="text-center">Sr No.</th>
        <th scope="col" class="text-center">Product Code</th>
        <th scope="col" class="text-center">Procuct</th>
        <th scope="col" class="text-center">Price {{ this.$currency }}</th>
        <th scope="col" class="text-center">Quantity</th>
        <th scope="col" class="text-center">Category</th>
        <th scope="col" class="text-center">Status</th>
        <th scope="col" class="text-center">Actions</th>
      </tr>
    </thead>

    <tbody v-if="showLoader == 1">
      <tr class="shimmer-td">
        <td colspan="9">
          <content-placeholders class="shimmer" :rounded="true">
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </td>
      </tr>
    </tbody>

    <tbody v-if="showLoader == 0 && displayedProducts.length > 0">
      <tr v-for="(product, index) in displayedProducts" :key="index">
        <td>
          <div class="company-link">
            <span>P-{{ index + 1 }}</span>
          </div>
        </td>
        <td>
          <div class="company-link">
            <span>{{ product.product_code }}</span>
          </div>
        </td>
        <td v-if="product.picture == null">
          <div class="company-details">
            <div class="company-logo">
              <img src="../../assets/images/product-image.png" alt="" />
            </div>
            <h6 class="company-name">{{ product.product_name }}</h6>
          </div>
        </td>
        <td v-else>
          <div class="company-details">
            <div class="company-logo">
              <img :src="API_URL + product.picture" alt="" />
            </div>
            <h6 class="company-name">{{ product.product_name }}</h6>
          </div>
        </td>

        <td>
          <div class="company-size">
            <span>{{ product.product_price }}</span>
          </div>
        </td>
        <td>
          <div class="company-location">
            <span
              >{{ product.product_quantity }} {{ product.product_unit }}</span
            >
          </div>
        </td>
        <td>
          <div class="company-location">
            <span v-if="product.category"
              >{{ product.category.category_code }} -
              {{ product.category.category_name }}</span
            >
          </div>
        </td>
        <td v-if="product.status == 1" class="text-center">
          <button class="active">Active</button>
        </td>
        <td v-else-if="product.status == 2" class="text-center">
          <button class="terminated">Not Active</button>
        </td>
        <td class="text-center">
          <div class="actions-btn">
            <i class="fa fa-pencil-alt" @click="editModal(product)"></i>
            <i class="fa fa-eye" @click="viewModal(product)"></i>
            <i class="fa fa-trash" @click="deleteModal(product.id)"></i>
          </div>
        </td>
      </tr>
    </tbody>

    <tbody v-if="showLoader == 0 && displayedProducts.length == 0">
      <tr class="no-data">
        <td colspan="9" class="shimmer">
          No Data Found.
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AllProducts",
  props: {
    displayedProducts: Array,
    showLoader: Number,
  },
  data() {
    return {
      API_URL: this.$productStorageURL,
    };
  },
  computed: {
    ...mapGetters("product", ["productsList"]),
  },

  methods: {
    ...mapActions({
      setProuctData: "product/setProuctData",
    }),

    editModal: function(product) {
      this.setProuctData(product);
      this.$router.push({ name: "EditProducts" });
    },
    viewModal: function(product) {
      this.setProuctData(product);
      this.$router.push({ name: "ViewProducts" });
    },
    async deleteModal(id) {
      var formData = new FormData();
      formData.append("id", id);
      this.$swal({
        title: "Are you sure you want to <br /> delete Product ?",
        text: "You can’t undo this action.",
        icon: "error",
        confirmButtonText: "Delete",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          return axios
            .post(process.env.VUE_APP_API_URL + "product/delete", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.$root.$emit("removeProduct", id);
                this.$swal("Deleted!", "Product has been deleted.", "success");
              } else {
                alert(response.data.message);
              }
            });
        }
      });
    },
  },
  mounted() {
    //  this.addProduct([]);
  },
};
</script>

<style></style>
